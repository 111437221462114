import React, {useState} from 'react'
import Hero from '../components/global/Hero'
import { graphql } from "gatsby"
import IntroSectionReuseable from '../components/reuseable/IntroSectionReuseable'
import CTASection from '../components/global/CTASection'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import {Styling} from '../styles/product/styled'
import Texture from '../components/animation/Texture'
import toggleOffPageShow from '../utils/toggleOffPageShow'
import toggleOffPageHide from '../utils/toggleOffPageHide'
import BrandsOffscreen from '../components/brands/BrandsOffscreen'
import { Helmet } from 'react-helmet'

export default function Products({data, pageContext}) {
    const brands = data.wpProduct.productFields.brands
    const [isActive, setIsActive] = useState(false);
    const [activeBrand, setActiveBrand] = useState(brands[0]);
  
  const handleActiveBrand = (e) => {
    let needle = e.target.closest('li').getAttribute('data-title')
    console.log(needle)
    let activeBrand = Array.from(brands).filter(brand => {
      if(brand.brand.title === needle){
        console.log('returned', brand)
        return brand
      }
    }) 
    // toggleOffPageHide('.brand', true)
    setActiveBrand(activeBrand[0])
    toggleOffPageShow({page:'product'})
  }

  

    return (
        <Styling>
        <Helmet>
            <title>{data.wpProduct.seo.title}</title>
            <meta name="description" content={data.wpProduct.seo.metaDesc} />
            <meta name="og:description" content={data.wpProduct.seo.metaDesc} />
            <meta property="og:title" content={data.wpProduct.seo.title} />          
            <meta property="og:image" content={'https://morlandbathrooms.com'+data.wpProduct.customHeroImage.heroImage.localFile.publicURL} />
        </Helmet>
        <Texture 
        className='texture full' 
        opacity={0.05}  
        position='absolute'
        bottom="0"
        height={''}
        top="0"
        watchScroll={false}
        />
        <div className="offpage-translate offpage-translate-bg"></div>
          <div className="offpage-translate">
            <Hero image={data.wpProduct.customHeroImage.heroImage} title={data.wpProduct.title}/>
            <IntroSectionReuseable productFields={data.wpProduct.productFields} />
            <div className="brands">
                <div className="title large libre">Our suppliers</div>
                <ul className="brands-list">
                    {
                        brands.map(brand => {
                            const theImage = getImage(brand.brand.brandsFields.logo.localFile);
                            return (
                              <li data-title={brand.brand.title} onClick={(e) => handleActiveBrand(e)}>
                                <GatsbyImage
                                image={theImage}
                                className="image brand"
                                alt={'test'}
                                title={'test'}
                                />
                              </li>
                            )
                        })
                    }
                </ul> 
            </div>
            <CTASection ctaType={'chat'} />
          </div>
          <BrandsOffscreen toggleOffPageHide={toggleOffPageHide} activeBrand={activeBrand} currentProduct={data.wpProduct.title} />
        </Styling>
    )
}

export const query = graphql`
  query($id: String!) {
    
    wpProduct( id: { eq: $id }) {
        id
        title
        seo {
          title
          metaDesc
        }
        customHeroImage {
          heroImage{
              localFile{
                publicURL
              }
          }
        }
        productFields {
            mainPageText
            mainPageTitle
            mainPageImage{
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 2000
                      placeholder: NONE
                      formats: [AUTO, WEBP]
                    )
                  }
                }
            }
            brands{
              brand {
                ... on WpBrand {
                  title

                  brandsAccessoriesImage{
                    accessoriesImage{
                        localFile{
                          absolutePath
                          childImageSharp {
                            gatsbyImageData(
                              width: 2000
                              placeholder: NONE
                              formats: [AUTO, WEBP]
                            )
                          }
                      }
                    }
                  }

                  brandsAccessoriesImageFocalPoint {
                    brandsAccessoriesImageFocalPoint
                  }

                  brandsBathImage{
                    bathImage{
                        localFile{
                          absolutePath
                          childImageSharp {
                            gatsbyImageData(
                              width: 2000
                              placeholder: NONE
                              formats: [AUTO, WEBP]
                            )
                          }
                      }
                    }
                  }

                  brandsBathImageFocalPoint {
                    brandsBathImageFocalPoint
                  }

                  brandsBathroomFurnitureImage{
                    bathroomFurnitureImage{
                        localFile{
                          absolutePath
                          childImageSharp {
                            gatsbyImageData(
                              width: 2000
                              placeholder: NONE
                              formats: [AUTO, WEBP]
                            )
                          }
                      }
                    }
                  }

                  brandsBathroomFurnitureImageFocalPoint {
                    brandsBathroomFurnitureImageFocalPoint
                  }

                  brandsBathroomTapsImage{
                    bathroomTapsImage{
                        localFile{
                          absolutePath
                          childImageSharp {
                            gatsbyImageData(
                              width: 2000
                              placeholder: NONE
                              formats: [AUTO, WEBP]
                            )
                          }
                      }
                    }
                  }

                  brandsBathroomTapsImageFocalPoint {
                    brandsBathroomTapsImageFocalPoint
                  }

                  brandsBathroomTilesImage{
                    bathroomTilesImage{
                        localFile{
                          absolutePath
                          childImageSharp {
                            gatsbyImageData(
                              width: 2000
                              placeholder: NONE
                              formats: [AUTO, WEBP]
                            )
                          }
                      }
                    }
                  }

                  brandsBathroomTilesImageFocalPoint {
                    brandsBathroomTilesImageFocalPoint
                  }

                  brandsRadiatorsImage{
                    radiatorsImage{
                        localFile{
                          absolutePath
                          childImageSharp {
                            gatsbyImageData(
                              width: 2000
                              placeholder: NONE
                              formats: [AUTO, WEBP]
                            )
                          }
                      }
                    }
                  }

                  brandsRadiatorsImageFocalPoint {
                    brandsRadiatorsImageFocalPoint
                  }

                  brandsSanitaryWareImage{
                    sanitaryWareImage{
                        localFile{
                          absolutePath
                          childImageSharp {
                            gatsbyImageData(
                              width: 2000
                              placeholder: NONE
                              formats: [AUTO, WEBP]
                            )
                          }
                      }
                    }
                  }

                  brandsSanitaryWareImageFocalPoint {
                    brandsSanitaryWareImageFocalPoint
                  }

                  brandsShowersImage{
                    showersImage{
                        localFile{
                          absolutePath
                          childImageSharp {
                            gatsbyImageData(
                              width: 2000
                              placeholder: NONE
                              formats: [AUTO, WEBP]
                            )
                          }
                      }
                    }
                  }

                  brandsShowersImageFocalPoint {
                    brandsShowersImageFocalPoint
                  }

                  brandsWetRoomImage{
                    wetRoomImage{
                        localFile{
                          absolutePath
                          childImageSharp {
                            gatsbyImageData(
                              width: 2000
                              placeholder: NONE
                              formats: [AUTO, WEBP]
                            )
                          }
                      }
                    }
                  }

                  brandsWetRoomsImageFocalPoint {
                    brandsWetRoomsImageFocalPoint
                  }

                

                  brandsFields {
                    description
                    website
                    mainImageFocalPoint
                    mainImage{
                      localFile{
                        absolutePath
                        childImageSharp {
                          gatsbyImageData(
                            width: 2000
                            placeholder: NONE
                            formats: [AUTO, WEBP]
                          )
                        }
                      }
                    }
                    logo {
                      localFile{
                        absolutePath
                        childImageSharp {
                          gatsbyImageData(
                            width: 2000
                            placeholder: NONE
                            formats: [AUTO, WEBP]
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
        }
        customHeroImage {
            heroImage {
                localFile{
                    childImageSharp {
                        gatsbyImageData(
                          width: 1500
                          placeholder: NONE
                          formats: [AUTO, WEBP]
                        )
                    }
                }
            }
        }
    }
  }
`