import React from 'react'
import styled from 'styled-components'

const Styling = styled.div`
z-index: 100;
position: relative;

.brands{
padding:0 2rem;
z-index: 100;
position: relative;

@media only screen and (min-width: 550px) {
    padding:0 4rem;
}

    .title{
    margin-bottom: 4rem;
    }

}

    .brands-list{
    position: relative;
    width: 100%;
    display: grid;
    grid-gap:2rem;
    grid-template-columns:repeat(2, 1fr);
    z-index: 100;
    position: relative;
    
    @media only screen and (min-width: 550px) {
    
        display: flex;
        flex-wrap: wrap;
    }

    li{
    display:flex;
    }
    
        .image{
        background:white;
        padding:1rem;
        max-height: 115px;
        
        @media only screen and (min-width: 550px) {
            max-width: 180px;
            margin-right:2rem;
            margin-bottom:2rem;
        }
        

            img{
            width: calc(100% - 2rem);
            height:calc(100% - 2rem);
            top:1rem;
            left:1rem;
            object-fit: contain !important;
            }

        }

    }

`

export { Styling }